<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item :to="{
                name: 'BranchNotificationList',
              }">通知總覽</b-breadcrumb-item>
            <b-breadcrumb-item active>檢視通知</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body" v-if="notification">
        <div class="mb-3">
          <h4 class="font-weight-bold">{{ notification.title }}</h4>
        </div>
        <p>發送時間: {{ notification.publish_at }}</p>
        <p v-if="notification.related_url">通知網址: <b-link :href="notification.related_url" target="_blank">{{ notification.related_url }}</b-link></p>
        <b-badge v-if="notification.notify_type == 'important'" pill variant="danger">此為重要通知</b-badge>
        <div id="notification_content" class="mt-3" v-html="htmlContent"></div>
      </div>
    </div>
  </div>
</template>

<script>
import notificationApi from "@/apis/notification";
import { mapActions, mapGetters } from 'vuex';
import { format } from "date-fns";

export default {
  name: "BranchNotificationDetail",

  data () {
    return {
      notification: null
    }
  },

  computed: {
    ...mapGetters("general", ["currentBranch"]),
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    htmlContent() {
      if (!this.notification?.content) return "";

      var el = document.createElement('html');
      el.innerHTML = this.notification.content;
      var links = el.getElementsByTagName('a');

      for (var i=0; i< links.length; i++){
        links[i].setAttribute('target', '_blank');
      }

      return el.innerHTML;
    }
  },

  async created () {
    await this.getNotification()
    this.readNotification()
  },

  methods: {
    ...mapActions("general", ["getUnreadNotificationCount"]),
    async getNotification () {
      try {
        const { data } = await notificationApi.getOneNotification(this.$route.params.notification_id);

        this.notification = data.data;
        this.notification.publish_at = format(new Date(data.data.publish_at), 'yyyy-MM-dd HH:mm:ss')
      } catch (error) {
        alert("取得通知錯誤");
      }
    },

    async readNotification () {
      try {
        await notificationApi.updateBranchNotificationRead(this.currentBranch.id, this.$route.params.notification_id);
        this.getUnreadNotificationCount()
      } catch (error) {
        alert("取得通知錯誤");
      }
    }
  }
};
</script>
